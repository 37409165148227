import React from 'react'
import Catalog from '../Components/Catalog'

export default function Home() {
    return (
        <div>
            <Catalog/>
        </div>
    )
}
