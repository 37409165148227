import React from 'react';
import {Row, Col} from 'react-bootstrap';
import Logo from '../Img/logo.jpeg';

export default function Header() {
    return (
            <div>
            <Row className="headerBox">
                <Col md={12}>
                    <a href="http://catalog.hcmseller.com">
                    <img className="headerLogo" src={Logo} alt="Logo"/>
                    </a>
                </Col>
            </Row>
            </div>
    )
}
