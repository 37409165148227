import React from 'react'
import Phone from '../Img/phone.png'
import Whatsapp from '../Img/whatsapp.png'
import Sms from '../Img/sms.png'

export default function Footer() {
    return (
        <div className="footerBox">
                <div className="iconBox borderRight">
                    <a href="tel:+91-9315257585">
                    <img className="actionIcon" src={Phone} alt="Phone"/>
                    </a>
                </div>

                <div className=" iconBox borderRight">
                    <a href="sms:9315257585">
                    <img className="actionIcon" src={Sms} alt="Phone"/>
                    </a>
                </div>

                <div className="iconBox">
                    <a href="https://wa.me/+91-9315257585?text=Hello!%20I%20am%20interested%20in%20your%20Products">
                    <img className="actionIcon" src={Whatsapp} alt="Phone"/>
                    </a>
                </div>
                
        </div>
    )
}
