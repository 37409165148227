import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import item1 from '../Img/facial.jpg'
import item2 from '../Img/mensanti.jpg'
import item3 from '../Img/ledlight.png'
import item4 from '../Img/smartwatch.jpg'
import item5 from '../Img/mensanti.jpg'
import item6 from '../Img/facial.jpg'

export default function Catalog() {
    return (
            <Container>
            <Row className="catalogList">
            <Col md={4} xs={6}>
                    <div className="catalogBox">
                        <img className="catalogImg" src={item1} alt="item"/>
                        <h2 className="itemTitle">Facial Massager For Smoothing Body Face Beauty Care And Electric Facial Massager</h2>
                        <Row className="itemPrice">
                            <Col md={6} className="m-t-10">
                            <div className="moq">Moq: 10 Pcs</div> 
                            </Col>
                            <Col md={6} className="m-t-10">
                            <div className="rate">₹120.00</div>
                            </Col>
                        </Row>
                        {/* <div className="itemPrice"><span className="moq">Moq: 10 Pcs</span> <span className="rate">₹120.00</span> </div> */}
                    </div>
                </Col>

                <Col md={4} xs={6}>
                    <div className="catalogBox">
                        <img className="catalogImg" src={item2} alt="item"/>
                        <h2 className="itemTitle">Facial Massager For Smoothing Body Face Beauty Care And Electric Facial Massager</h2>
                        <Row className="itemPrice">
                            <Col md={6} className="m-t-10">
                            <div className="moq">Moq: 10 Pcs</div> 
                            </Col>
                            <Col md={6} className="m-t-10">
                            <div className="rate">₹120.00</div>
                            </Col>
                        </Row>
                        {/* <div className="itemPrice"><span className="moq">Moq: 10 Pcs</span> <span className="rate">₹120.00</span> </div> */}
                    </div>
                </Col>

                <Col md={4} xs={6}>
                    <div className="catalogBox">
                        <img className="catalogImg" src={item3} alt="item"/>
                        <h2 className="itemTitle">Facial Massager For Smoothing Body Face Beauty Care And Electric Facial Massager</h2>
                        <Row className="itemPrice">
                            <Col md={6} className="m-t-10">
                            <div className="moq">Moq: 10 Pcs</div> 
                            </Col>
                            <Col md={6} className="m-t-10">
                            <div className="rate">₹120.00</div>
                            </Col>
                        </Row>
                        {/* <div className="itemPrice"><span className="moq">Moq: 10 Pcs</span> <span className="rate">₹120.00</span> </div> */}
                    </div>
                </Col>
                <Col md={4} xs={6}>
                    <div className="catalogBox">
                        <img className="catalogImg" src={item4} alt="item"/>
                        <h2 className="itemTitle">Facial Massager For Smoothing Body Face Beauty Care And Electric Facial Massager</h2>
                        <Row className="itemPrice">
                            <Col md={6} className="m-t-10">
                            <div className="moq">Moq: 10 Pcs</div> 
                            </Col>
                            <Col md={6} className="m-t-10">
                            <div className="rate">₹120.00</div>
                            </Col>
                        </Row>
                        {/* <div className="itemPrice"><span className="moq">Moq: 10 Pcs</span> <span className="rate">₹120.00</span> </div> */}
                    </div>
                </Col>
                <Col md={4} xs={6}>
                    <div className="catalogBox">
                        <img className="catalogImg" src={item5} alt="item"/>
                        <h2 className="itemTitle">Facial Massager For Smoothing Body Face Beauty Care And Electric Facial Massager</h2>
                        <Row className="itemPrice">
                            <Col md={6} className="m-t-10">
                            <div className="moq">Moq: 10 Pcs</div> 
                            </Col>
                            <Col md={6} className="m-t-10">
                            <div className="rate">₹120.00</div>
                            </Col>
                        </Row>
                        {/* <div className="itemPrice"><span className="moq">Moq: 10 Pcs</span> <span className="rate">₹120.00</span> </div> */}
                    </div>
                </Col>
                <Col md={4} xs={6}>
                    <div className="catalogBox">
                        <img className="catalogImg" src={item6} alt="item"/>
                        <h2 className="itemTitle">Facial Massager For Smoothing Body Face Beauty Care And Electric Facial Massager</h2>
                        <Row className="itemPrice">
                            <Col md={6} className="m-t-10">
                            <div className="moq">Moq: 10 Pcs</div> 
                            </Col>
                            <Col md={6} className="m-t-10">
                            <div className="rate">₹120.00</div>
                            </Col>
                        </Row>
                        {/* <div className="itemPrice"><span className="moq">Moq: 10 Pcs</span> <span className="rate">₹120.00</span> </div> */}
                    </div>
                </Col>
            </Row>
            </Container>
    )
}
